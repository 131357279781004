import React from 'react';
import "./index.scss";
import Header from '../../components/Header';
import Button from '../../components/Button';
import Footer from '../../components/Footer';

import { useNavigate } from 'react-router-dom';

import animateModule from "../../modules/animateModule";
import * as backendModule from "../../modules/backendModule";
import axios from 'axios';

export default function Career() {
    const [mobile, setMobile] = React.useState(false);
    const navigate = useNavigate();

    const animateNavigate = (to) => {
        animateModule(navigate, to, document.querySelector('#root'));
    }

    const FormModal = (props) => {
        const [selectedFile, setSelectedFile] = React.useState(null);
        const nameJobRef = React.useRef(null);
        const emailJobRef = React.useRef(null);
        const messageJobRef = React.useRef(null);

        const sendJobData = (e) => {
            let data = {
                Name: nameJobRef.current.value,
                EMail: emailJobRef.current.value,
                attachment: selectedFile,
                Message: messageJobRef.current.value
            }

            let fd = new FormData()
            Object.keys(data).forEach(elem => {
                fd.append(elem, data[elem])
            })

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/email/jobApplication`,
                data: fd,
                ...backendModule.axiosConfig,
                headers: {
                    ...backendModule.axiosConfig.headers,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((e) => {
                if (e.data.status === 'ok') {
                    if (e.data.status === 'ok') {
                        setSuccess(true);
                    } else {

                    }

                }
            }).catch((e) => {

            })
        }

        const [success, setSuccess] = React.useState(false);

        return <div style={props.style} className='component__formModal'>
            <div style={{ display: success ? 'none' : '' }} className='component__formModal__box'>
                <img id='close' src='images/close.png' alt='' onClick={() => setModal(false)} />
                <strong>Please fill the required fields</strong>
                <div id='row'>
                    <div>
                        <p>Full name</p>
                        <input ref={nameJobRef} type='text' placeholder='Please enter your full name' />
                    </div>
                    <div>
                        <p>Email adress</p>
                        <input ref={emailJobRef} type='text' placeholder='Plese enter your email adress' />
                    </div>
                </div>
                <div id='file'>
                    <p>Attach resume</p>
                    <div id='fileinput'>
                        <div>{selectedFile !== null ? selectedFile.name : 'Choose file'}</div>
                        <button onClick={(e) => {
                            let input = e.target.parentNode.querySelector("input");
                            if (input) input.click();
                        }} >
                            Browse
                        </button>
                        <input type="file" onChange={(e) => { setSelectedFile(e.target.files[0]) }}
                            accept='.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .rtf, .pdf, .txt'
                        ></input>
                    </div>
                </div>
                <div>
                    <p>Why do you want to work with us?</p>
                    <textarea ref={messageJobRef} placeholder='Message' />
                </div>
                <button onClick={() => sendJobData()}>Submit application</button>
            </div>
            <div style={{ display: success ? 'block' : 'none' }} className='component__formModal__box finish'>
                <img id='close' src='images/close.png' alt='' onClick={() => setModal(false)} />
                <strong>
                    Congratulations! Your application has been successfully submitted
                </strong>
                <p id='con'>
                    Thank you for submitting your application. We appreciate your interest in our company and the time you have taken to apply for the position. Our recruitment team will review your application and if your qualifications match the requirements of the position, we will be in touch with you to schedule an interview.
                </p>
                <button onClick={() => animateNavigate('/')}>Go to home page</button>
            </div>
        </div>
    }

    const [modal, setModal] = React.useState(false);

    const nameRef = React.useRef(null);
    const phoneRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const companyRef = React.useRef(null);
    const textRef = React.useRef(null);

    const sendData = (e) => {
        let data = {
            Name: nameRef.current.value,
            CompanyName: companyRef.current.value,
            PhoneNumber: phoneRef.current.value,
            EMail: emailRef.current.value,
            Message: textRef.current.value
        }
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/email/contact`,
            data: data,
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                nameRef.current.value = '';
                phoneRef.current.value = '';
                companyRef.current.value = '';
                emailRef.current.value = '';
                textRef.current.value = '';
                console.log(e);
            } else {

            }
        }).catch((e) => {

        })

    }

    const [activeTab, setActiveTab] = React.useState(null);

    return (
        <>
            <FormModal style={{ display: modal ? 'flex' : 'none' }} />
            <div className='route__career'>
                <Header />
                <div style={{ display: activeTab === null ? '' : 'none' }} className='route__career__box'>
                    <h1>Career</h1>
                    <p>
                        Discover endless opportunities for growth and advancement in your career with us.
                        Our company values diversity, inclusivity, and continuous learning, making it a great place to develop your skills and reach your full potential.
                        <br></br><br></br>
                        Join a team of dedicated and passionate professionals and take the next step in your career today!
                    </p>
                    <div className='route__career__box__tabs'>
                        <div className='route__career__box__tabs__tab'>
                            <div>
                                <strong>Marketing manager</strong>
                                <p>Full time, Srebrenik, remote</p>
                            </div>
                            <button id='btnApply' onClick={() => setActiveTab('marketing')} >Read more</button>
                        </div>
                        <div className='route__career__box__tabs__tab'>
                            <div>
                                <strong>Mobile app developer</strong>
                                <p>Full time, Srebrenik, remote</p>
                            </div>
                            <button id='btnApply' onClick={() => setActiveTab('mobileapp')} >Read more</button>
                        </div>
                        <div className='route__career__box__tabs__tab'>
                            <div>
                                <strong>Full stack developer</strong>
                                <p>Full time, Srebrenik, remote</p>
                            </div>
                            <button id='btnApply' onClick={() => setActiveTab('developer')} >Read more</button>
                        </div>
                        <div className='route__career__box__tabs__tab'>
                            <div>
                                <strong>Affiliate manager</strong>
                                <p>Full time, Srebrenik, remote</p>
                            </div>
                            <button id='btnApply' onClick={() => setActiveTab('affiliate')} >Read more</button>
                        </div>
                        <div className='route__career__box__tabs__tab'>
                            <div>
                                <strong>UI/UX designer</strong>
                                <p>Full time, Srebrenik, remote</p>
                            </div>
                            <button id='btnApply' onClick={() => setActiveTab('uiux')} >Read more</button>
                        </div>
                        <div className='route__career__box__tabs__tab'>
                            <div>
                                <strong>Certified accountant</strong>
                                <p>Full time, Srebrenik</p>
                            </div>
                            <button id='btnApply' onClick={() => setActiveTab('certified')} >Read more</button>
                        </div>
                    </div>
                </div>

                <div className='route__career__box' style={{ display: activeTab === null ? 'none' : '' }}>
                    <div className='route__career__box__content'>
                        {
                            activeTab === 'marketing' &&
                            <>
                                <h1>Marketing manager</h1>
                                <p id='desc'>Position description</p>
                                <p>
                                    <li>Developing and implementing marketing strategies and plans to promote a product, service, or brand</li>
                                    <li>Conducting market research to identify and analyze target audiences, competitors, and industry trends</li>
                                    <li> Collaborating with other departments (such as product development, sales, and advertising) to coordinate efforts</li>
                                    <li>Managing budgets and allocating resources effectively</li>
                                    <li>Monitoring and analyzing performance metrics to evaluate the effectiveness of marketing campaigns and make adjustments as necessary</li>
                                    <li>Strong communication and leadership skills to guide and motivate a team</li>
                                    <li>Bachelor's degree in marketing, business, or a related field</li>
                                    <li>Several years of experience in marketing or a related field</li>
                                    <li>Familiarity with digital marketing tools and techniques.</li>
                                    <li>Knowledge of marketing analytics and the ability to make data-driven decisions.</li>
                                    <li>Strong project management skills.</li>
                                    <li>Strong written and verbal communication skills.</li>
                                    <li>Strong interpersonal skills and ability to work well with cross-functional teams.</li>
                                </p>
                                <div>
                                    <button onClick={() => setActiveTab(null)}>Back</button>
                                    <button onClick={() => setModal(true)}>Apply</button>
                                </div>
                            </>
                        }
                        {
                            activeTab === 'mobileapp' &&
                            <>
                                <h1>Mobile app developer</h1>
                                <p id='desc'>Position description</p>
                                <p>
                                    A mobile app developer is responsible for designing, developing, and maintaining mobile applications for various platforms, such as iOS and Android. They work with a team of designers and developers to create new features and improve existing ones. They also test the app for bugs and compatibility issues, and fix any problems that arise. Other responsibilities may include:
                                    <br></br><br></br>
                                    <li>Collaborating with cross-functional teams to define, design, and ship new features</li>
                                    <li>Identifying and correcting bottlenecks and fixing bugs</li>
                                    <li>Helping maintain code quality, organization, and automatization</li>
                                    <li>Continuously discovering, evaluating, and implementing new technologies to maximize development efficiency</li>
                                </p>
                                <div>
                                    <button onClick={() => setActiveTab(null)}>Back</button>
                                    <button onClick={() => setModal(true)}>Apply</button>
                                </div>
                            </>
                        }
                        {
                            activeTab === 'developer' &&
                            <>
                                <h1>Full stack developer</h1>
                                <p id='desc'>Position description</p>
                                <p>
                                    A full stack developer is responsible for designing, developing, and maintaining the front-end and back-end aspects of web and mobile applications. They work with a team of designers and developers to create new features and improve existing ones. They also test the app for bugs and compatibility issues, and fix any problems that arise. Other responsibilities may include:
                                    <br></br><br></br>
                                    <li>Writing clean, maintainable, and efficient code</li>
                                    <li>Designing and implementing low-latency, high-availability, and performance applications</li>
                                    <li>Integration of user-facing elements developed by front-end developers with server-side logic</li>
                                    <li>Implementation of security and data protection</li>
                                    <li>Performance tuning, improvement, balancing, usability and responsiveness of applications</li>
                                </p>
                                <div>
                                    <button onClick={() => setActiveTab(null)}>Back</button>
                                    <button onClick={() => setModal(true)}>Apply</button>
                                </div>
                            </>
                        }
                        {
                            activeTab === 'affiliate' &&
                            <>
                                <h1>Affiliate manager</h1>
                                <p id='desc'>Position description</p>
                                <p>
                                    An affiliate manager is responsible for recruiting, managing and maintaining relationships with affiliate partners to promote a company's products or services. They work to develop and implement strategies to increase sales and revenue through affiliate marketing. Other responsibilities may include:
                                    <br></br><br></br>
                                    <li>Recruiting and onboarding new affiliate partners</li>
                                    <li>Setting performance goals and targets for affiliate partners</li>
                                    <li> Communicating regularly with affiliate partners to provide support and guidance</li>
                                    <li>Tracking and analyzing affiliate performance data to optimize strategies</li>
                                </p>
                                <div>
                                    <button onClick={() => setActiveTab(null)}>Back</button>
                                    <button onClick={() => setModal(true)}>Apply</button>
                                </div>
                            </>
                        }
                        {
                            activeTab === 'uiux' &&
                            <>
                                <h1>UI/UC designer</h1>
                                <p id='desc'>Position description</p>
                                <p>
                                    A UI/UX designer is responsible for designing the overall user experience and user interface of web and mobile applications. They work to create visually appealing, intuitive, and functional designs that provide a seamless user experience. Other responsibilities may include:
                                    <br></br><br></br>
                                    <li>Conducting user research and testing to understand user needs and preferences</li>
                                    <li>Creating wireframes, prototypes, and high-fidelity mockups</li>
                                    <li>Developing and maintaining design systems and style guides</li>
                                    <li>Collaborating with developers and other stakeholders to ensure the design is technically feasible and aligned with business goals</li>
                                </p>
                                <div>
                                    <button onClick={() => setActiveTab(null)}>Back</button>
                                    <button onClick={() => setModal(true)}>Apply</button>
                                </div>
                            </>
                        }
                        {
                            activeTab === 'certified' &&
                            <>
                                <h1>Certified accountant</h1>
                                <p id='desc'>Position description</p>
                                <p>
                                    A certified accountant (CA) is a professional who has completed a rigorous education and training program, passed a series of exams, and met other requirements to earn the CA designation. They are responsible for providing financial and accounting services to individuals, businesses, and organizations. Their duties may include:
                                     <br></br><br></br>
                                    <li>Preparing and analyzing financial statements</li>
                                    <li>Assessing financial operations and making recommendations for improvement</li>
                                    <li>Assisting clients with tax planning and compliance</li>
                                    <li>Advising clients on financial decisions, such as investments and business expansions</li>
                                    <li>Auditing financial records to ensure compliance with laws and regulations</li>
                                </p>
                                <div>
                                    <button onClick={() => setActiveTab(null)}>Back</button>
                                    <button onClick={() => setModal(true)}>Apply</button>
                                </div>
                            </>
                        }
                    </div>
                </div>

                <div className='route__home__sectionThree'>
                    <img id='home-string' src='images/string.png' alt='' />
                    <div className='route__home__sectionThree__left'>
                        <h1>Contact us</h1>
                        <span style={{ fontSize: mobile ? '16px' : '' }}><div style={{ borderBottom: '1px solid black', height: '2px', width: mobile ? '30px' : '126px' }}></div>  We will answer within 24 hours </span>
                        <p>Find out about the difference when working with services tailored to your brand.</p>
                    </div>
                    <div className='route__home__sectionThree__right'>
                        <div className='route__home__sectionThree__right__form'>
                            <div>
                                <input ref={nameRef} type='text' placeholder='Your name' />
                                <input ref={phoneRef} type='text' placeholder='Phone number' />
                                <input ref={emailRef} type='text' placeholder='Email' />
                                <Button id='contact-us-button-third' value='Contact us' width='269px' height='57px' fontSize='22px' onClick={() => sendData()} />
                            </div>
                            <div>
                                <input ref={companyRef} type='text' placeholder='Company' />
                                <textarea ref={textRef} placeholder='Message' />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
