import React from 'react';
import Button from '../Button';
import "./index.scss";

export default function Footer() {
  return (
    <div className='component__footer'>
      <div className='component__footer__gridbox'>
        <div id='f-item1'>
          <img src='images/logoWhite.svg' alt='' />
          <br></br>
          <p>A company which brings you towards the scaling and growth.</p>
          <a>Terms and conditions</a>
          <a>Privacy policy</a>
        </div>
        <div id='f-item1'>
          <h1>Legal information</h1>
          <p>Obscale d.o.o</p>
          <p>Tax: 4210371090001</p>
          <a>Vat: 210371090001</a>
          <a>Date of establishment 5/13/2019</a>
          <a>Registration number: 32-01-0118-19</a>

          <br />
          
          <p style={{width: "100%", borderTop: "1px solid gray", paddingTop: "15px"}}>Flority d.o.o</p>
          <p>Tax: 80/31-04875-4</p>
          <p>Vat: 51093413</p>
          <p>Date of establishment 05/12/2022</p>
          
        </div>
        <div id='f-item2'>
          <h1>Contact us</h1>
          <span><img src='images/locationIcon.png' alt='' /> Obscale d.o.o. <br></br> Cehaje bb, 75350 Srebrenik</span>
          <span><img src='images/locationIcon.png' alt='' /> Flority d.o.o. <br></br> Bulevar Revolucije A3, BAR, Crna Gora</span>
          <span><img src='images/email-header.png' alt='' /> info@obscale.com</span>
        </div>
        <div id='f-item3'>
          <h1>Get in touch</h1>
          <input type='text' placeholder='Email' />
          <Button ml='0' mr='auto' value='Contact us' width='150px' height='40px' fontSize='16px' />
        </div>
      </div>
      <div className='component__footer__copyright'>
        <p>Copyright ©2022 Scale Group. All rights Reserved</p>
        <span>
          <a href='https://www.facebook.com/obscale.doo'><img src='images/facebook (4) 1.png' alt='' /></a>
          <a href='https://www.instagram.com/obscale_doo/'><img src='images/instagram (2) 1.png' alt='' /></a>
          <a href='https://www.linkedin.com/company/obscale/'><img src='images/linkedin (2) 1.png' alt='' /></a>
        </span>
      </div>
    </div>
  )
}
