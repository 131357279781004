import React from 'react';
import "./index.scss";

export default function Button(props) {
  return (
    <button onClick={props.onClick} id = {props.id} style={{width: props.width, height: props.height, fontSize: props.fontSize, fontWeight: props.fontWeight, display:'block', marginLeft: props.ml, marginRight: props.mr}} className='component__button'>
        {props.value}
    </button>
  )
}
