import React from 'react';
import "./index.scss";
import Header from '../../components/Header';
import Button from '../../components/Button';
import Footer from "../../components/Footer";
import * as backendModule from "../../modules/backendModule";
import axios from 'axios';

export default function Home() {

  const [mobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    let width = window.screen.width;
    if (width < 557) {
      setMobile(true);
    }
    else {
      setMobile(false);
    }
  }, []);

  const [mobileSec, setMobileSec] = React.useState(false);

  React.useEffect(() => {
    let width2 = window.screen.width;
    if (width2 < 380) {
      setMobileSec(true);
    }
    else {
      setMobileSec(false);
    }
  }, []);

  const divRef = React.useRef();
  const divRef2 = React.useRef();
  const divRef3 = React.useRef();
  const divRef4 = React.useRef();

  React.useEffect(() => {
    if (!divRef.current) return;

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          divRef.current.classList.add('animate');
          divRef2.current.classList.add('animate');
          divRef3.current.classList.add('animate');
          divRef4.current.classList.add('animate');
        };
      });
    });
    observer.observe(divRef.current);

    return () => {
      try {
        observer.unobserve(divRef.current);
      } catch { };
    };
  }, [divRef.current]);

  const scrollToFooter = () => {
    document.querySelector('.route__home__sectionThree').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  const nameRef = React.useRef(null);
  const phoneRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const companyRef = React.useRef(null);
  const textRef = React.useRef(null);

  const sendData = (e) => {
    let data = {
        Name: nameRef.current.value,
        CompanyName: companyRef.current.value,
        PhoneNumber: phoneRef.current.value,
        EMail: emailRef.current.value,
        Message: textRef.current.value
    }
    axios({
        method: "POST",
        url: `${backendModule.backendURL}/email/contact`,
        data: data,
        ...backendModule.axiosConfig
    }).then((e) => {
        if (e.data.status === 'ok') {
            nameRef.current.value = '';
            phoneRef.current.value = '';
            companyRef.current.value = '';
            emailRef.current.value = '';
            textRef.current.value = '';
            console.log(e);
        } else {

        }
    }).catch((e) => {

    })

}

  return (
    <div className='route__home'>
      <Header />
      <div className='route__home__sectionOne'>
        <div className='route__home__sectionOne__left'>
          <span><div style={{ borderBottom: '1px solid black', height: '2px', width: '70px' }}></div>Trusted by +70 companies</span>
          <h1>Obscale Group</h1>
          <br></br><br></br>
          <span id='bars'><p>Innovation</p>|<p>Persistence</p>|<p>Excellence</p></span>
          <p id='left-text'>
            Obscale group is an innovative company behind a
            the Scale brand that offers premium services tailored to brands in affiliate marketing, software development, and nutra manufacturing.
          </p>
          <div className='route__home__sectionOne__left__buttons'>
            <Button value='Contact us' width='269px' height='57px' fontSize='22px' fontWeight='500' onClick={() => scrollToFooter()} />
            <span><strong>Our companies</strong><img src='images/arrowRight.png' alt='' /></span>
          </div>
        </div>
        <div className='route__home__sectionOne__right'>
          <div>
            <img id='img1' src='images/right1.png' alt='' />
            <img id='img2' src='images/right2.png' alt='' />
            <img id='img3' src='images/right3.png' alt='' />
            <img id='img4' src='images/right4.png' alt='' />
          </div>
        </div>
      </div>

      <div className='route__home__sectionTwo'>
        <div className='route__home__sectionTwo__mask'></div>
        <div className='route__home__sectionTwo__gridbox'>
          <div id='item1' className='route__home__sectionTwo__gridbox__item--upper'>
            <span><div style={{ borderBottom: '1px solid white', height: '2px', width: '100px' }}></div>  Trusted by +70 companies</span>
            <h1>Meet our companies</h1>
          </div>

          <div id='item2' className='route__home__sectionTwo__gridbox__item--upper'>
            <p>
              Our philosophy is embedded in our companies' DNA through their dedication to creating innovative products and services tailored to each brand's needs.
            </p>
          </div>

          <div ref={divRef} id='item3' className='route__home__sectionTwo__gridbox__item'>
            <img src='images/middle1.png' alt='' />
            <div className='route__home__sectionTwo__gridbox__item__foo' id='f0'>
              <span><div style={{ borderBottom: '1px solid white', height: '2px', width: mobileSec ? '50px' : '80px' }}></div>  Obscale Dev</span>
              <div id='foo-foo'>
                <p>
                  Specialized in developing beautiful and reliable software solutions programmed from scratch with minimal usage of external libraries.
                </p>
                <a href='https://dev.obscale.com/'><img src='images/arrowCircle.png' alt='' /></a>
              </div>
            </div>
          </div>

          <div ref={divRef2} id='item4' className='route__home__sectionTwo__gridbox__item'>
            <div className='route__home__sectionTwo__gridbox__item__foo' id='f1'>
              <span><div style={{ borderBottom: '1px solid #11002F', height: '2px', width: mobileSec ? '50px' : '100px' }}></div>  Scale Lead</span>
              <div id='foo-foo'>
                <p>
                  Our digital marketing network connects premium advertisers and publishers with their target audience.
                </p>
                <a href='https://scale-lead.com/'><img src='images/arrowCircle.png' alt='' /></a>
              </div>
            </div>
            <img src='images/middle2.png' alt='' />
          </div>

          <div ref={divRef3} id='item5' className='route__home__sectionTwo__gridbox__item'>
            <div className='route__home__sectionTwo__gridbox__item__foo' id='f1'>
              <span><div style={{ borderBottom: '1px solid #11002F', height: '2px', width: mobileSec ? '50px' : '80px' }}></div>  Pharm Scale</span>
              <div id='foo-foo'>
                <p>
                  Premium Nutra manufacturing facility with high-quality items at an affordable price for your white-label products.
                </p>
                <a href='https://pharmscale.com/'><img src='images/arrowCircle.png' alt='' /></a>
              </div>
            </div>
            <img src='images/middle3.png' alt='' />
          </div>

          <div ref={divRef4} id='item6' className='route__home__sectionTwo__gridbox__item'>
            <img src='images/middle4.png' alt='' />
            <div className='route__home__sectionTwo__gridbox__item__foo' id='f0'>
              <span><div style={{ borderBottom: '1px solid white', height: '2px', width: mobileSec ? '50px' : '100px' }}></div>  Scale Pick</span>
              <div id='foo-foo'>
                <p>
                  Personalized native ads with the best CPC on the market for publishers and an in-depth audience targeting ad placement system for advertisers.
                </p>
                <a href='https://scalepick.com/'><img src='images/arrowCircle.png' alt='' /></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='route__home__sectionThree'>
        <img id='home-string' src='images/string.png' alt='' />
        <div className='route__home__sectionThree__left'>
          <h1>Contact us</h1>
          <span style={{ fontSize: mobile ? '16px' : '' }}><div style={{ borderBottom: '1px solid black', height: '2px', width: mobile ? '30px' : '126px' }}></div>  We will answer within 24 hours </span>
          <p>Find out about the difference when working with services tailored to your brand.</p>
        </div>
        <div className='route__home__sectionThree__right'>
          <div className='route__home__sectionThree__right__form'>
            <div>
              <input ref={nameRef} type='text' placeholder='Your name' />
              <input ref={phoneRef} type='text' placeholder='Phone number' />
              <input ref={emailRef} type='text' placeholder='Email' />
              <Button id='contact-us-button-third' value='Contact us' width='269px' height='57px' fontSize='22px' onClick={() => sendData()} />
            </div>
            <div>
              <input ref={companyRef} type='text' placeholder='Company' />
              <textarea ref={textRef} placeholder='Message' />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
