import React from 'react';
import Header from '../../components/Header';
import "./index.scss";
import Button from '../../components/Button';
import Footer from '../../components/Footer';

export default function AboutUs() {
    return (
        <div className='route__aboutus'>
            <Header />
            <div className='route__aboutus__sectionOne'>
                <h1>About us</h1>
                <p>
                From our  beginnings marked by 18 hours work days in a small garage to a company with 17 employees and clients all over the world our approach has never changed. Hard work, persistence and constant innovation proved to be a winning formula for all of our projects.
                </p>
            </div>
            <div className='route__aboutus__sectionTwo'>
                <h1>Our beginnings</h1>
                <div id='line'></div>
                <p>
                    We started as a business oriented towards e-commerce and developed into a premium platform for affiliate marketing that connects the best publishers and advertisers all over the world.
                </p>
            </div>
            <div className='route__aboutus__sectionThree'>
                <div className='route__aboutus__sectionThree__gridbox'>
                    <div className='route__aboutus__sectionThree__gridbox__left'>
                        <h1>Team Work Makes the Dream Work</h1>
                        <p>
                            Scale Dev was born in collaboration with our friends, the top programming talent in the region. We wanted to offer what we were missing when we started our online business - a top-notch software development service that delivers reliable and beautiful apps and web pages.
                        </p>
                        <div className='route__aboutus__sectionThree__gridbox__left__cols'>
                            <div>
                                <p>
                                    What started as a digital marketing boutique combined with traditional commerce evolved into Obscale - a company with 50 employees
                                    (and growing :)) working in four different units and countries.
                                </p>
                            </div>
                            <div>
                                <p>
                                    Our crowning achievement came in 2022 when we opened PharmScale -
                                    a factory specialized in premium nutra manufacturing located in Srebrenik, Bosnia-Herzegovina.
                                </p>
                                <Button id='aboutusButton' value='Find out more' fontWeight='500' />
                            </div>
                        </div>
                    </div>
                    <div className='route__aboutus__sectionThree__gridbox__right'>
                        <img id='about-string' src='images/string.png' alt='' />
                        <div className='route__aboutus__sectionThree__gridbox__right__imgbox'>
                            <img src='images/esef.png' alt='' />
                            <div className='route__aboutus__sectionThree__gridbox__right__imgbox__footer'>
                                <span><div></div><h1>Ešef Ćosić</h1></span>
                                <p>Founder & CEO</p>
                            </div>
                        </div>
                        <div className='route__aboutus__sectionThree__gridbox__right__imgbox'>
                            <img src='images/senad.png' alt='' />
                            <div className='route__aboutus__sectionThree__gridbox__right__imgbox__footer'>
                                <span><div></div><h1>Senad Bećirović</h1></span>
                                <p>Founder & CEO</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='route__aboutus__sectionFour'>
                <img src='images/Logo section.png' alt='' />
            </div>
            <Footer />
        </div>
    )
}
