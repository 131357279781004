import React from 'react';
import "./index.scss";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Footer from "../../components/Footer";

export default function Companies() {

    const divRef1 = React.useRef();
    const divRef2 = React.useRef();
    const divRef3 = React.useRef();
  
    React.useEffect(() => {
      if (!divRef1.current) return;
  
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            divRef1.current.classList.add('animate');
          };
        });
      });
      observer.observe(divRef1.current);
  
      return () => {
        try {
          observer.unobserve(divRef1.current);
        } catch {};
      };
    }, [divRef1.current]);

    React.useEffect(() => {
        if (!divRef2.current) return;
    
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              divRef2.current.classList.add('animate');
            };
          });
        });
        observer.observe(divRef2.current);
    
        return () => {
          try {
            observer.unobserve(divRef2.current);
          } catch {};
        };
      }, [divRef2.current]);

      React.useEffect(() => {
        if (!divRef3.current) return;
    
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              divRef3.current.classList.add('animate');
            };
          });
        });
        observer.observe(divRef3.current);
    
        return () => {
          try {
            observer.unobserve(divRef3.current);
          } catch {};
        };
      }, [divRef3.current]);

    return (
        <div className='route__companies'>
            <Header />
            <div className='route__companies__sectionOne'>
                <h1>Scale excellence network</h1>
                <p>Composed of experts, tried and tested in their area of expertise, Scale brands units are geared towards providing top class services tailored to a brand strategy that will organically connect you with your target audience.</p>
            </div>
            <div className='route__companies__sectionTwo'>
                <div className='route__companies__sectionTwo__gridbox'>
                    <div className='route__companies__sectionTwo__gridbox__left'>
                        <h1>Scale Dev</h1>
                        <p>
                            With programmers and marketers with 15+ years of experience, we are proud to craft software solutions, webpages, and applications built from scratch with minimal usage of external libraries.
                        </p>
                        <div className='route__companies__sectionTwo__gridbox__left__cols'>
                            <div>
                                <p>The end results are beautiful web pages and apps optimized specifically for your brand. </p>
                                <p>That means the fastest performance, best optimization, and most reliability. </p>
                            </div>
                            <div>
                                <p>
                                    Most of our software solutions run for months without the slightest interruption. Even after that, all that is usually needed are minor adjustments and fine-tuning.
                                </p>
                                <Button id='findmoreButton' value='Find out more'  fontWeight='500' />
                            </div>
                        </div>
                    </div>
                    <div className='route__companies__sectionTwo__gridbox__right'>
                        <img src='images/laptop.png' alt='' />
                    </div>
                </div>
            </div>
            <div className='route__companies__sectionThree'>
                <img id='string-two' src='images/string.png' alt='' />
                <div ref={divRef1} className='route__companies__sectionThree__gridboxOne'>
                    <div id='g1-1'>
                        <img src='images/laptop2.png' alt='' />
                    </div>
                    <div id='g1-2'>
                        <h1>Scale Lead</h1>
                        <p>
                            Our triple win-win-win network excellence connects high-quality advertisers, publishers & target audiences and offers the best CPA on the market.
                            <br></br>
                            <br></br>
                            Most affiliate marketers settle for a conversion rate of 60%. We like to keep that number closer to 80%.
                        </p>
                        <Button id='findhowButton' value='Find out how we do it' fontWeight='500' />
                    </div>
                </div>
                <div ref={divRef2} className='route__companies__sectionThree__gridboxTwo'>
                    <div className='route__companies__sectionThree__gridboxTwo__left'>
                        <h1>Scale Pick</h1>
                        <p>
                            The future of native ads just got personalized.
                            <br></br>
                            <br></br>
                            We welcome all high-quality publishers with the best CPC on the market, and NO minimum traffic.
                        </p>
                        <div className='route__companies__sectionThree__gridboxTwo__left__cols'>
                            <div>
                                <p>
                                    Our advertisers get dedicated account managers with more than 10 years of digital marketing experience that ensure your ads get the best possible exposure to the right target audience.
                                </p>
                            </div>
                            <div>
                                <p>
                                    Our in-depth audience targeting system places your ads on the right website at the right time with the appropriate content.
                                </p>
                                <Button id='scalepickButton' value='Discover Scale Pick' fontWeight='500' />
                            </div>
                        </div>
                    </div>
                    <div className='route__companies__sectionThree__gridboxTwo__right'>
                        <img src='images/laptop3.png' alt='' />
                    </div>
                </div>
            </div>
            <div className='route__companies__sectionFour'>
                <div ref={divRef3} className='route__companies__sectionFour__gridbox'>
                    <div className='route__companies__sectionFour__gridbox__right'>
                        <img src='images/laptop4.png' alt='' />
                    </div>
                    <div className='route__companies__sectionFour__gridbox__left'>
                        <h1>Pharm Scale</h1>
                        <p>
                            Create your brand with a premium nutra manufacturing service at an affordable price for your white-label products...
                        </p>
                        <div className='route__companies__sectionFour__gridbox__left__cols'>
                            <div>
                                <p>
                                    Our new factory is equipped for manufacturing complete turnkey products, from soft gelatin dosage forms and two-piece hardshell to our multi-pill sachets, bottling, and blister packaging options.
                                </p>
                            </div>
                            <div>
                                <p>
                                    We are also your one-stop solution for professional designers and superior printing and packaging, so you can fully dedicate yourself to building a brand strategy.
                                </p>
                                <Button id='scalepickButton' value='This is what it looks like' fontWeight='500' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
