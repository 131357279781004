import React from 'react';
import "./index.scss";
import { Link, useNavigate } from 'react-router-dom';
import animateModule from "../../modules/animateModule";

export default function Header() {

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const openMenuHandler = () => {
    setOpen(o => !o);
  }

  const scrollToFooter = () => {
    document.querySelector('.component__footer').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }


  const animateNavigate = (to) => {
    animateModule(navigate, to, document.querySelector('#root'));
  }

  return (
    <div className='component__header'>
      <img src='images/logo.svg' />
      <div style={{ display: open ? 'block' : '' }} className='component__header__navbar'>
        <div style={{ display: open ? 'flex' : 'none' }} className='component__header__navbar__mobile' >
          <img src='images/logoWhite.svg' alt='' />
          <img id='close' src='images/close.png' alt='' onClick={() => openMenuHandler()} />
        </div>
        <p onClick={() => animateNavigate('/')}>HOME</p>
        <p onClick={() => animateNavigate('/aboutus')}>ABOUT US</p>
        <p onClick={() => animateNavigate('/companies')}>OUR PRODUCTS</p>
        <p onClick={() => scrollToFooter()}>CONTACT US</p>
        <p onClick={() => animateNavigate('/career')}>CAREER</p>
        <div style={{ display: open ? 'flex' : 'none' }} className='component__header__navbar__mobile--foo'>
          <a href='https://www.facebook.com/obscale.doo'><img src='images/facebook (4) 1.png' alt='' /></a>
          <a href='https://www.instagram.com/obscale_doo/'><img src='images/instagram (2) 1.png' alt='' /></a>
          <a href='https://www.linkedin.com/company/obscale/'><img src='images/linkedin (2) 1.png' alt='' /></a>
        </div>
      </div>
      <div className='component__header__block'>
        <span><img src='images/email-header.png' /><p>info@obscale.com</p></span>
        <img onClick={() => openMenuHandler()} id='hamburger' src='images/hamburger.png' alt='' />
      </div>
    </div>
  )
}
