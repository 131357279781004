import { Route, Routes} from "react-router-dom";
import Home from "../src/routes/Home";
import "./App.css";
import Companies from "./routes/Companies";
import AboutUs from "./routes/AboutUs";
import Career from "./routes/Career";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/career" element={<Career />} />
        </Routes>
    </div>
  );
}

export default App;